
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import selectedTemplateApi from '../../api/SelectedTemplate';

import {
    CREATE_SELECTED_TEMPLATE,
    READ_SELECTED_TEMPLATE,
    UPDATE_SELECTED_TEMPLATE,
    DELETE_SELECTED_TEMPLATE,
    DELETE_TEMPLATE_SELECTED_TEMPLATE,
    DETAILS_SELECTED_TEMPLATE
} from '../actions';

import {
    createSelectedTemplateSuccess,
    createSelectedTemplateError,

    readSelectedTemplateSuccess,
    readSelectedTemplateError,

    updateSelectedTemplateSuccess,
    updateSelectedTemplateError,

    deleteSelectedTemplateSuccess,
    deleteSelectedTemplateError,

    deleteTemplateSelectedTemplateSuccess,
    deleteTemplateSelectedTemplateError,
    
    detailsSelectedTemplateSuccess,
    detailsSelectedTemplateError
} from './actions';



export function* watchCreateSelectedTemplate() {
    yield takeEvery(CREATE_SELECTED_TEMPLATE, createSelectedTemplate);
}
const createSelectedTemplateAsync = async (data) => {
    return await selectedTemplateApi.create(data)
        .then(result => result)
        .catch(error => error);
}
function* createSelectedTemplate({ payload }) {
    
    try {
        const result = yield call(createSelectedTemplateAsync, payload);
        if (result.errorMessage) {
            yield put(createSelectedTemplateError(result.errorMessage));
        } else {
            yield put(createSelectedTemplateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(createSelectedTemplateError(error));

    }
}


export function* watchReadSelectedTemplate() {
    yield takeEvery(READ_SELECTED_TEMPLATE, readSelectedTemplate);
}
const readSelectedTemplateAsync = async (data) => {
    return await selectedTemplateApi.read(data)
        .then(result => result)
        .catch(error => error);
}
function* readSelectedTemplate({ payload }) {
    try {
        const result = yield call(readSelectedTemplateAsync, payload);
        if(result.status === 401){
            window.localStorage.clear();
            window.location.href = "/";
        }
        if (result.errorMessage) {
            yield put(readSelectedTemplateError(result.errorMessage));
        } else {
            yield put(readSelectedTemplateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(readSelectedTemplateError(error));

    }
}
        

export function* watchUpdateSelectedTemplate() {
    yield takeEvery(UPDATE_SELECTED_TEMPLATE, updateSelectedTemplate);
}
const updateSelectedTemplateAsync = async (data) => {
    return await selectedTemplateApi.update(data)
        .then(result => result)
        .catch(error => error);
}
function* updateSelectedTemplate({ payload }) {
    try {
        const result = yield call(updateSelectedTemplateAsync, payload);
        if (result.errorMessage) {
            yield put(updateSelectedTemplateError(result.errorMessage));
        } else {
            yield put(updateSelectedTemplateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(updateSelectedTemplateError(error));

    }
}


export function* watchDeleteSelectedTemplate() {
    yield takeEvery(DELETE_SELECTED_TEMPLATE, deleteSelectedTemplate);
}
const deleteSelectedTemplateAsync = async (id) => {
    return await selectedTemplateApi.delete(id)
        .then(result => result)
        .catch(error => error);
}
function* deleteSelectedTemplate({ payload }) {
    try {
        const result = yield call(deleteSelectedTemplateAsync, payload);
        if (result.errorMessage) {
            yield put(deleteSelectedTemplateError(result.errorMessage));
        } else {
            yield put(deleteSelectedTemplateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(deleteSelectedTemplateError(error));

    }
}


export function* watchDeleteTemplateSelectedTemplate() {
    yield takeEvery(DELETE_TEMPLATE_SELECTED_TEMPLATE, deleteTemplateSelectedTemplate);
}
const deleteTemplateSelectedTemplateAsync = async (id) => {
    return await selectedTemplateApi.deleteTemplate(id)
        .then(result => result)
        .catch(error => error);
}
function* deleteTemplateSelectedTemplate({ payload }) {
    try {
        const result = yield call(deleteTemplateSelectedTemplateAsync, payload);
        if (result.errorMessage) {
            yield put(deleteTemplateSelectedTemplateError(result.errorMessage));
        } else {
            yield put(deleteTemplateSelectedTemplateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(deleteTemplateSelectedTemplateError(error));

    }
}


export function* watchDetailsSelectedTemplate() {
    yield takeEvery(DETAILS_SELECTED_TEMPLATE, detailsSelectedTemplate);
}
const detailsSelectedTemplateAsync = async (id) => {
    return await selectedTemplateApi.details(id)
        .then(result => result)
        .catch(error => error);
}
function* detailsSelectedTemplate({ payload }) {
    try {
        const result = yield call(detailsSelectedTemplateAsync, payload);
        if (result.errorMessage) {
            yield put(detailsSelectedTemplateError(result.errorMessage));
        } else {
            yield put(detailsSelectedTemplateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(detailsSelectedTemplateError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchCreateSelectedTemplate),
        fork(watchReadSelectedTemplate),
        fork(watchUpdateSelectedTemplate),
        fork(watchDeleteSelectedTemplate),
        fork(watchDeleteTemplateSelectedTemplate),
        fork(watchDetailsSelectedTemplate),
    ]);
}