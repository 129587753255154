
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import genericApi from '../../api/Generic';
import {
    GET_DATA,
    CREATE_DATA,
    UPDATE_DATA,
    DELETE_DATA,
    DELETE_ITEM_DATA,
    IMPORT_DATA,
    UPLOAD_FILE,
} from '../actions';

import {
    getDataSuccess,
    getDataError,
    createDataSuccess,
    createDataError,
    updateDataSuccess,
    updateDataError,
    deleteDataSuccess,
    deleteDataError,
    deleteItemDataSuccess,
    deleteItemDataError,
    importDataSuccess,
    importDataError,
    uploadFileSuccess,
    uploadFileError
} from './actions';



export function* watchGetData() {
    yield takeEvery(GET_DATA, getData);
}

const getDataAsync = async (requestType,request) =>
    await genericApi.get(requestType, request)
            .then(resultData => resultData)
            .catch(error => error);  

function* getData({ payload }) {
    const { requestType, request, history, operation, callback } = payload;
    try {
        const resultData = yield call(getDataAsync, requestType,request);
        if(resultData.status === 401){
            window.localStorage.clear();
            window.location.href = "/";
        }
        if (resultData.errorMessage) {
            yield put(getDataError(resultData.data.data.message, operation));

            if(callback !== null){
                callback([]);
            }
        } else {
            resultData.data.data.data['operation'] = operation;
            yield put(getDataSuccess(resultData.data.data.data));

            if(callback !== null){
                callback(resultData.data.data.data);
            }
        }

    } catch (error) {
        yield put(getDataError(error, operation));

    }
}

export function* watchCreateData() {
    yield takeEvery(CREATE_DATA, createData);
}

const createDataAsync = async (requestType,request) =>
    await genericApi.post(requestType, request)
            .then(resultData => resultData)
            .catch(error => error);  

function* createData({ payload }) {
    const { requestType, request, history, operation } = payload;
    try {
        const resultData = yield call(createDataAsync, requestType,request);
        
        if (resultData.errorMessage) {
            yield put(createDataError(resultData.data.data.message, operation));
        } else {
            resultData.data.data.data['operation'] = operation;
            yield put(createDataSuccess(resultData.data.data.data));
        }

    } catch (error) {
        yield put(createDataError(error, operation));

    }
}


export function* watchUpdateData() {
    yield takeEvery(UPDATE_DATA, updateData);
}

const updateDataAsync = async (requestType,request) =>
    await genericApi.patch(requestType, request)
            .then(resultData => resultData)
            .catch(error => error);  

function* updateData({ payload }) {
    const { requestType, request, history, operation } = payload;
    try {
        const resultData = yield call(updateDataAsync, requestType,request);
        
        if (resultData.errorMessage) {
            yield put(updateDataError(resultData.data.data.message, operation));
        } else {
            resultData.data.data.data['operation'] = operation;
            yield put(updateDataSuccess(resultData.data.data.data));
        }

    } catch (error) {
        yield put(updateDataError(error, operation));

    }
}

export function* watchDeleteData() {
    yield takeEvery(DELETE_DATA, deleteData);
}

const deleteDataAsync = async (requestType,request) =>
    await genericApi.delete(requestType,request)
            .then(resultData => resultData)
            .catch(error => error);   

function* deleteData({ payload }) {
    const { requestType, request, history } = payload;
    try {
        const resultData = yield call(deleteDataAsync, requestType,request);
        
        if (resultData.errorMessage) {
            yield put(deleteDataError(resultData.data.data.message));
        } else {
            yield put(deleteDataSuccess(resultData.data.data.data));
        }

    } catch (error) {
        yield put(deleteDataError(error));

    }
}


export function* watchDeleteItemData() {
    yield takeEvery(DELETE_ITEM_DATA, deleteItemData);
}

const deleteItemDataAsync = async (requestType,request) =>
    await genericApi.delete(requestType,request)
            .then(resultData => resultData)
            .catch(error => error);   

function* deleteItemData({ payload }) {
    const { requestType, request, history } = payload;
    try {
        const resultData = yield call(deleteItemDataAsync, requestType,request);
        
        if (resultData.errorMessage) {
            yield put(deleteItemDataError(resultData.data.data.message));
        } else {
            yield put(deleteItemDataSuccess(resultData.data.data.data));
        }

    } catch (error) {
        yield put(deleteItemDataError(error));

    }
}


export function* watchImportData() {
    yield takeEvery(IMPORT_DATA, importData);
}

const importDataAsync = async (requestType,request) =>
    await genericApi.import(requestType, request)
            .then(resultData => resultData)
            .catch(error => error);  

function* importData({ payload }) {
    const { requestType, request, history } = payload;
    try {
        const resultData = yield call(importDataAsync, requestType,request);
        if (resultData.errorMessage) {
            yield put(importDataError(resultData.errorMessage.error.message));
        } else {
            yield put(importDataSuccess(resultData.data.data.message));
        }

    } catch (error) {
        yield put(importDataError(error));

    }
}

export function* watchUploadFile() {
    yield takeEvery(UPLOAD_FILE, uploadFile);
}


const uploadFileAsync = async (requestType,request) => 
    await genericApi.importLalaMove(requestType, request)
            .then(resultData =>  resultData)
            .catch(error => error);  


function* uploadFile({ payload }) {
    const { requestType, request, history } = payload;
    try {
        const resultData = yield call(uploadFileAsync, requestType,request);
        if (resultData.errorMessage) {
            yield put(uploadFileError(resultData.errorMessage.error.message));
        } else {
            yield put(uploadFileSuccess(resultData.data));
        }

    } catch (error) {
        yield put(uploadFileError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchCreateData),
        fork(watchDeleteData),
        fork(watchDeleteItemData),
        fork(watchGetData),
        fork(watchUpdateData),
        fork(watchImportData),
        fork(watchUploadFile)
    ]);
}