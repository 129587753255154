import {
    GET_VENDORS,
    GET_VENDORS_SUCCESS,
    GET_VENDORS_ERROR,
    TOGGLE_VENDOR,
    TOGGLE_VENDOR_SUCCESS,
    TOGGLE_VENDOR_ERROR
  } from '../actions';
  
  export const getVendors = (request, history) => ({
    type: GET_VENDORS,
    payload: { request, history }
  });
  export const getVendorsSuccess = (data) => ({
    type: GET_VENDORS_SUCCESS,
    payload: data
  });
  export const getVendorsError = (message) => ({
    type: GET_VENDORS_ERROR,
    payload: { message }
  });
  
  
  export const toggleVendors = (request, history) => ({
    type: TOGGLE_VENDOR,
    payload: { request, history }
  });
  export const toggleVendorsSuccess = (data) => ({
    type: TOGGLE_VENDOR_SUCCESS,
    payload: data
  });
  export const toggleVendorsError = (message) => ({
    type: TOGGLE_VENDOR_ERROR,
    payload: { message }
  });
  
  
  