import {
    GET_VENDORS,
    GET_VENDORS_SUCCESS,
    GET_VENDORS_ERROR,
    TOGGLE_VENDOR,
    TOGGLE_VENDOR_SUCCESS,
    TOGGLE_VENDOR_ERROR
} from '../actions';

const INIT_STATE = {
    data: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VENDORS:
            return { ...state, loading: true, error: '' };
        case GET_VENDORS_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '' };
        case GET_VENDORS_ERROR:
            return { ...state, loading: false, data: [], error: action.payload };
        case TOGGLE_VENDOR:
            return { ...state, loading: true, error: '' };
        case TOGGLE_VENDOR_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '' };
        case TOGGLE_VENDOR_ERROR:
            return { ...state, loading: false, data: [], error: action.payload };
        default: return { ...state };
    }
}
