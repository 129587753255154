import {
    CREATE_SELECTED_TEMPLATE,
    CREATE_SELECTED_TEMPLATE_SUCCESS,
    CREATE_SELECTED_TEMPLATE_ERROR,

    READ_SELECTED_TEMPLATE,
    READ_SELECTED_TEMPLATE_SUCCESS,
    READ_SELECTED_TEMPLATE_ERROR,
    
    UPDATE_SELECTED_TEMPLATE,
    UPDATE_SELECTED_TEMPLATE_SUCCESS,
    UPDATE_SELECTED_TEMPLATE_ERROR,

    DELETE_SELECTED_TEMPLATE,
    DELETE_SELECTED_TEMPLATE_SUCCESS,
    DELETE_SELECTED_TEMPLATE_ERROR,

    DELETE_TEMPLATE_SELECTED_TEMPLATE,
    DELETE_TEMPLATE_SELECTED_TEMPLATE_SUCCESS,
    DELETE_TEMPLATE_SELECTED_TEMPLATE_ERROR,

    DETAILS_SELECTED_TEMPLATE,
    DETAILS_SELECTED_TEMPLATE_SUCCESS,
    DETAILS_SELECTED_TEMPLATE_ERROR
  } from '../actions';
  

  export const createSelectedTemplate = (data) => ({
    type: CREATE_SELECTED_TEMPLATE,
    payload: data
  });
  export const createSelectedTemplateSuccess = (data) => ({
    type: CREATE_SELECTED_TEMPLATE_SUCCESS,
    payload: data
  });
  export const createSelectedTemplateError = (message) => ({
    type: CREATE_SELECTED_TEMPLATE_ERROR,
    payload: { message }
  });
  

  export const readSelectedTemplate = () => ({
    type: READ_SELECTED_TEMPLATE,
    payload: {}
  });
  export const readSelectedTemplateSuccess = (data) => ({
    type: READ_SELECTED_TEMPLATE_SUCCESS,
    payload: data
  });
  export const readSelectedTemplateError = (message) => ({
    type: READ_SELECTED_TEMPLATE_ERROR,
    payload: { message }
  });


  export const updateSelectedTemplate = (data) => ({
    type: UPDATE_SELECTED_TEMPLATE,
    payload: data
  });
  export const updateSelectedTemplateSuccess = (data) => ({
    type: UPDATE_SELECTED_TEMPLATE_SUCCESS,
    payload: data
  });
  export const updateSelectedTemplateError = (message) => ({
    type: UPDATE_SELECTED_TEMPLATE_ERROR,
    payload: { message }
  });


  export const deleteSelectedTemplate = (id) => ({
    type: DELETE_SELECTED_TEMPLATE,
    payload: id
  });
  export const deleteSelectedTemplateSuccess = (data) => ({
    type: DELETE_SELECTED_TEMPLATE_SUCCESS,
    payload: data
  });
  export const deleteSelectedTemplateError = (message) => ({
    type: DELETE_SELECTED_TEMPLATE_ERROR,
    payload: { message }
  });


  export const deleteTemplateSelectedTemplate = (id) => ({
    type: DELETE_TEMPLATE_SELECTED_TEMPLATE,
    payload: id
  });
  export const deleteTemplateSelectedTemplateSuccess = (data) => ({
    type: DELETE_TEMPLATE_SELECTED_TEMPLATE_SUCCESS,
    payload: data
  });
  export const deleteTemplateSelectedTemplateError = (message) => ({
    type: DELETE_TEMPLATE_SELECTED_TEMPLATE_ERROR,
    payload: { message }
  });


  export const detailsSelectedTemplate = (id) => ({
    type: DETAILS_SELECTED_TEMPLATE,
    payload: id
  });
  export const detailsSelectedTemplateSuccess = (data) => ({
    type: DETAILS_SELECTED_TEMPLATE_SUCCESS,
    payload: data
  });
  export const detailsSelectedTemplateError = (message) => ({
    type: DETAILS_SELECTED_TEMPLATE_ERROR,
    payload: { message }
  });