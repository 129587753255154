import {
    CREATE_LOCATION,
    CREATE_LOCATION_SUCCESS,
    CREATE_LOCATION_ERROR,

    READ_LOCATION,
    READ_LOCATION_SUCCESS,
    READ_LOCATION_ERROR,
    
    UPDATE_LOCATION,
    UPDATE_LOCATION_SUCCESS,
    UPDATE_LOCATION_ERROR,

    DELETE_LOCATION,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_ERROR,

    DELETE_LOCATION_PORT,
    DELETE_LOCATION_PORT_SUCCESS,
    DELETE_LOCATION_PORT_ERROR,
    
    DETAILS_LOCATION,
    DETAILS_LOCATION_SUCCESS,
    DETAILS_LOCATION_ERROR,

    SEARCH_LOCATION,
    SEARCH_LOCATION_SUCCESS,
    SEARCH_LOCATION_ERROR
} from '../actions';


const INIT_STATE = {
    loading: false,
    result: null,
    error: '', 
    created: false,
    deleted: false,
    updated: false,
    selected: false,
    search: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_LOCATION:
            return { 
                ...state, 
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case CREATE_LOCATION_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: true,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case CREATE_LOCATION_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };

        case READ_LOCATION:
            return { 
                ...state, 
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case READ_LOCATION_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: true ,
                search: false
            };
        case READ_LOCATION_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };

        case UPDATE_LOCATION:
            return { 
                ...state, 
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case UPDATE_LOCATION_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false,
                deleted: false,
                updated: true,
                selected: false,
                search: false
            };
        case UPDATE_LOCATION_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        
        case DELETE_LOCATION:
            return { 
                ...state,
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case DELETE_LOCATION_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false, 
                deleted: true,
                updated: false,
                selected: false,
                search: false
            };
        case DELETE_LOCATION_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        
        case DELETE_LOCATION_PORT:
            return { 
                ...state,
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case DELETE_LOCATION_PORT_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false, 
                deleted: true,
                updated: false,
                selected: false,
                search: false
            };
        case DELETE_LOCATION_PORT_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };

        case DETAILS_LOCATION:
            return { 
                ...state,
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case DETAILS_LOCATION_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false, 
                deleted: false,
                updated: false,
                selected: true,
                search: false
            };
        case DETAILS_LOCATION_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };

        case SEARCH_LOCATION:
            return { 
                ...state, 
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
        case SEARCH_LOCATION_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: true
            };
        case SEARCH_LOCATION_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false,
                search: false
            };
            
        default: return { ...state };
    }
}
