import {
    GET_DATA,
    GET_DATA_SUCCESS,
    GET_DATA_ERROR,
    CREATE_DATA,
    CREATE_DATA_SUCCESS,
    CREATE_DATA_ERROR,
    UPDATE_DATA,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_ERROR,
    DELETE_DATA,
    DELETE_DATA_SUCCESS,
    DELETE_DATA_ERROR,
    DELETE_ITEM_DATA,
    DELETE_ITEM_DATA_SUCCESS,
    DELETE_ITEM_DATA_ERROR,
    IMPORT_DATA,
    IMPORT_DATA_SUCCESS,
    IMPORT_DATA_ERROR,    
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR
} from '../actions';

const INIT_STATE = {
    data: [],
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA:
            return { ...state, loading: true, error: '', operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'get', is_success: false };
        case GET_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '', operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'get', is_success: true };
        case GET_DATA_ERROR:
            return { ...state, loading: false, data: [], error: action.payload, operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'get', is_success: false };
        case CREATE_DATA:
            return { ...state, loading: true, error: '', operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'create', is_success: false };
        case CREATE_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '', operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'create', is_success: true };
        case CREATE_DATA_ERROR:
            return { ...state, loading: false, data: [], error: action.payload, operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'create', is_success: false };
        case UPDATE_DATA:
            return { ...state, loading: true, error: '', operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'update', is_success: false };
        case UPDATE_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '', operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'update', is_success: true };
        case UPDATE_DATA_ERROR:
            return { ...state, loading: false, data: [], error: action.payload, operation: (action.payload && action.payload.operation && action.payload.operation !== '') ? action.payload.operation : 'update', is_success: false };
        case DELETE_DATA:
            return { ...state, loading: true, error: '', operation: 'delete', is_success: false };
        case DELETE_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '', operation: 'delete', is_success: true };
        case DELETE_DATA_ERROR:
            return { ...state, loading: false, data: [], error: action.payload, operation: 'delete', is_success: false };
        case DELETE_ITEM_DATA:
            return { ...state, loading: true, error: '', operation: 'deleteItem', is_success: false };
        case DELETE_ITEM_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '', operation: 'deleteItem', is_success: true };
        case DELETE_ITEM_DATA_ERROR:
            return { ...state, loading: false, data: [], error: action.payload, operation: 'deleteItem', is_success: false };
        case IMPORT_DATA:
            return { ...state, loading: true, error: '', operation: '', is_success: false };
        case IMPORT_DATA_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '', operation: 'import', is_success: true };
        case IMPORT_DATA_ERROR:
            return { ...state, loading: false, data: [], error: action.payload, operation: 'import', is_success: false };   
        case UPLOAD_FILE:
            return { ...state, loading: true, error: '', operation: '', is_success: false };
        case UPLOAD_FILE_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: '', operation: 'import', is_success: true };
        case UPLOAD_FILE_ERROR:
            return { ...state, loading: false, data: [], error: action.payload, operation: 'import', is_success: false };
        default: return { ...state };
    }
}
