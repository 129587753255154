import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import vendorSagas from './vendor/saga';
import columbusSagas from './columbus/saga';
import locationSagas from './locations/saga';
import exchangeRateSagas from './exchangeRate/saga';
import vendorRateSagas from './vendorRate/saga';
import selectedTemplateSagas from './selectedTemplate/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    vendorSagas(),
    columbusSagas(),
    locationSagas(),
    exchangeRateSagas(),
    vendorRateSagas(),
    selectedTemplateSagas(),
  ]);
}
