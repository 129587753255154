import axios from 'axios';


const { 
    REACT_APP_API_URL
} = process.env;


const PATH = "locations";

const locationsApi = {

    read: async (data) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH;
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status };
    },

    create: async (data) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH;

        const getResult = await axios.post(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status };
    },

    update: async (data) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH;

        const getResult = await axios.patch(
            URL,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status };
    },

    delete: async (id) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH + '/' + id;

        const getResult = await axios.delete(
            URL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status };
    },
    
    deletePort: async (id) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH + '/destination/' + id;

        const getResult = await axios.delete(
            URL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status };
    },

    searchByContry: async (countryName) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH + '/' + countryName + '/search';
        
        const getResult = await axios.get(
            URL,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status };
    },

    search: async (data) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH + '/search';
        
        const getResult = await axios.get(
            URL,
            { 
                params: data,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
            
        return { data: getResult, errorMessage: errorMessage, status: status };
    },

    details: async (id) => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH + '/' + id;
        
        const getResult = await axios.get(
            URL,
            { 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status };
    },
}

export default locationsApi;