import axios from 'axios';

const { 
    REACT_APP_API_URL
} = process.env;


const getApiPath = (requestType) => {
    switch(requestType){
        case "vendor":
            return "vendors";
        case "vendorRate":
            return "vendor-rate";
        case "vendorRateTemplate":
            return "vendor-rate/template";
        case "vendorRateSearchLowestRate":
            return "vendor-rate/search/lowest-rate";
        case "rateType":
            return "ratetype";
        case "chargeType":
            return "chargeType";
        case "month":
            return "month";
        case "sectionType":
            return "sectiontype";
        case "vendorSection":
            return "vendor-section";
        case "vendorSectionTemplate":
            return "vendor-section/template";
        case "ports":
            return "ports";
        case "portsDestination":
            return "ports/destination";
        case "vendorRateTemplateIsActive":
            return "vendor-rate/template/is-active";
        case "dashboardVendorRateChart":
            return "dashboard/vendor-rate-chart";
        case "exchangeRates":
            return "rates";
        case "ratetype":
            return "ratetype";
        case "hubspotListDeals":
            return "hubspot/list-deals";
        case "hubspotListDealsDetails":
            return "hubspot/detail-deal";
        case "hubspotListSalePerson":
            return "hubspot/list-sale-persons";
        case "hubspotDealSearch":
            return "hubspot/deal/search";
        case "hubspotContactSearch":
            return "hubspot/contact/search";
        case "hubspotSalePerson":
            return "hubspot/sale-person";
        case "quotation":
            return "quotation";
        case "quotationLineitem":
            return "quotation/lineitem";
        case "quotationGeneratePdfAndSend":
            return "quotation/generate-pdf-and-send";
        case "transitInfo":
            return "transit-info";
        case "transitInfoDestination":
            return "transit-info/destination";
        default:
                return requestType;
    }
};

const genericApi = {
    
    get: async (requestType, request) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + getApiPath(requestType);
        const access_token = localStorage.getItem("access_token");

        const getResult = await axios.get(
            URL,
            {
                params: request,
                data: request,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    post: async (requestType,request) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + getApiPath(requestType);
        const access_token = localStorage.getItem("access_token");

        const result = await axios.post(
            URL,
            request,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: result, errorMessage: errorMessage, status: status};
    },
    
    patch: async (requestType,request) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + getApiPath(requestType);
        const access_token = localStorage.getItem("access_token");

        const result = await axios.patch(
            URL,
            request,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: result, errorMessage: errorMessage, status: status};
    },
    delete: async (requestType,id) => {
        let errorMessage = "";
        let status = 0;


        const URL = REACT_APP_API_URL + getApiPath(requestType) + "/" + id;
        const access_token = localStorage.getItem("access_token");

        const result = await axios.delete(
            URL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: result, errorMessage: errorMessage, status: status};
    },

    import: async (requestType,request) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + getApiPath(requestType) + '/template/import';
        const access_token = localStorage.getItem("access_token");

        let formData = new FormData();
        formData.append('filename', request)
        
        const result = await axios.post(
            URL,
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: result, errorMessage: errorMessage, status: status};
    }, 
    importLalaMove: async (requestType,request) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + '/lalamove';
        const access_token = localStorage.getItem("access_token");

        let formData = new FormData();
        formData.append('filename', request)
        
        const result = await axios.post(
            URL,
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${access_token}`
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
            var fileDownload = require('js-file-download');
            //fileDownload(result.data, 'processed-data.xlsx');

            
            const link = document.createElement('a');
            link.href = REACT_APP_API_URL.replace("/api","") + result.data.data.file;
            link.setAttribute('download', 'processed.xlsx');
            document.body.appendChild(link);
            link.click();
            
        return { data: result, errorMessage: errorMessage, status: status};
    },
}

export default genericApi;