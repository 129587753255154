import axios from 'axios';

const { 
    REACT_APP_API_URL
} = process.env;

const PATH = "auth/";

const authApi = {
    
    login: async (email, password) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + PATH + 'login';

        const getResult = await axios.post(
            URL,
            {
                username: email,
                password: password
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    register: async (name, username, password, email) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + PATH + 'register';

        const getResult = await axios.post(
            URL,
            {
                name: name,
                username: username,
                password: password,
                email: email,
            },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    logout: async () => {
        let errorMessage = "";
        let status = 0;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const URL = REACT_APP_API_URL + PATH + 'logout';

        const getResult = await axios.put(
            URL,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },
}

export default authApi;