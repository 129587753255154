
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import exchangeRateApi from '../../api/ExchangeRate';

import {
    CREATE_EXCHANGE_RATE,
    READ_EXCHANGE_RATE,
    UPDATE_EXCHANGE_RATE,
    DELETE_EXCHANGE_RATE
} from '../actions';

import {
    createExchangeRateSuccess,
    createExchangeRateError,

    readExchangeRateSuccess,
    readExchangeRateError,

    updateExchangeRateSuccess,
    updateExchangeRateError,

    deleteExchangeRateSuccess,
    deleteExchangeRateError
} from './actions';



export function* watchCreateExchangeRate() {
    yield takeEvery(CREATE_EXCHANGE_RATE, createExchangeRate);
}
const createExchangeRateAsync = async (data) => {
    return await exchangeRateApi.create(data)
        .then(result => result)
        .catch(error => error);
}
function* createExchangeRate({ payload }) {
    
    try {
        const result = yield call(createExchangeRateAsync, payload);
        if (result.errorMessage) {
            yield put(createExchangeRateError(result.errorMessage));
        } else {
            yield put(createExchangeRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(createExchangeRateError(error));

    }
}


export function* watchReadExchangeRate() {
    yield takeEvery(READ_EXCHANGE_RATE, readExchangeRate);
}
const readExchangeRateAsync = async (data) => {
    return await exchangeRateApi.read(data)
        .then(result => result)
        .catch(error => error);
}
function* readExchangeRate({ payload }) {
    try {
        const result = yield call(readExchangeRateAsync, payload);
        if(result.status === 401){
            window.localStorage.clear();
            window.location.href = "/";
        }
        if (result.errorMessage) {
            yield put(readExchangeRateError(result.errorMessage));
        } else {
            yield put(readExchangeRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(readExchangeRateError(error));

    }
}
        

export function* watchUpdateExchangeRate() {
    yield takeEvery(UPDATE_EXCHANGE_RATE, updateExchangeRate);
}
const updateExchangeRateAsync = async (data) => {
    return await exchangeRateApi.update(data)
        .then(result => result)
        .catch(error => error);
}
function* updateExchangeRate({ payload }) {
    try {
        const result = yield call(updateExchangeRateAsync, payload);
        if (result.errorMessage) {
            yield put(updateExchangeRateError(result.errorMessage));
        } else {
            yield put(updateExchangeRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(updateExchangeRateError(error));

    }
}


export function* watchDeleteExchangeRate() {
    yield takeEvery(DELETE_EXCHANGE_RATE, deleteExchangeRate);
}
const deleteExchangeRateAsync = async (id) => {
    return await exchangeRateApi.delete(id)
        .then(result => result)
        .catch(error => error);
}
function* deleteExchangeRate({ payload }) {
    try {
        const result = yield call(deleteExchangeRateAsync, payload);
        if (result.errorMessage) {
            yield put(deleteExchangeRateError(result.errorMessage));
        } else {
            yield put(deleteExchangeRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(deleteExchangeRateError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchCreateExchangeRate),
        fork(watchReadExchangeRate),
        fork(watchUpdateExchangeRate),
        fork(watchDeleteExchangeRate),
    ]);
}