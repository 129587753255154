
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import vendorRateApi from '../../api/VendorRate';

import {
    CREATE_VENDOR_RATE,
    READ_VENDOR_RATE,
    UPDATE_VENDOR_RATE,
    DELETE_VENDOR_RATE,
    DELETE_TEMPLATE_VENDOR_RATE,
    DETAILS_VENDOR_RATE
} from '../actions';

import {
    createVendorRateSuccess,
    createVendorRateError,

    readVendorRateSuccess,
    readVendorRateError,

    updateVendorRateSuccess,
    updateVendorRateError,

    deleteVendorRateSuccess,
    deleteVendorRateError,

    deleteTemplateVendorRateSuccess,
    deleteTemplateVendorRateError,
    
    detailsVendorRateSuccess,
    detailsVendorRateError
} from './actions';



export function* watchCreateVendorRate() {
    yield takeEvery(CREATE_VENDOR_RATE, createVendorRate);
}
const createVendorRateAsync = async (data) => {
    return await vendorRateApi.create(data)
        .then(result => result)
        .catch(error => error);
}
function* createVendorRate({ payload }) {
    
    try {
        const result = yield call(createVendorRateAsync, payload);
        if (result.errorMessage) {
            yield put(createVendorRateError(result.errorMessage));
        } else {
            yield put(createVendorRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(createVendorRateError(error));

    }
}


export function* watchReadVendorRate() {
    yield takeEvery(READ_VENDOR_RATE, readVendorRate);
}
const readVendorRateAsync = async (data) => {
    return await vendorRateApi.read(data)
        .then(result => result)
        .catch(error => error);
}
function* readVendorRate({ payload }) {
    try {
        const result = yield call(readVendorRateAsync, payload);
        if(result.status === 401){
            window.localStorage.clear();
            window.location.href = "/";
        }
        if (result.errorMessage) {
            yield put(readVendorRateError(result.errorMessage));
        } else {
            yield put(readVendorRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(readVendorRateError(error));

    }
}
        

export function* watchUpdateVendorRate() {
    yield takeEvery(UPDATE_VENDOR_RATE, updateVendorRate);
}
const updateVendorRateAsync = async (data) => {
    return await vendorRateApi.update(data)
        .then(result => result)
        .catch(error => error);
}
function* updateVendorRate({ payload }) {
    try {
        const result = yield call(updateVendorRateAsync, payload);
        if (result.errorMessage) {
            yield put(updateVendorRateError(result.errorMessage));
        } else {
            yield put(updateVendorRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(updateVendorRateError(error));

    }
}


export function* watchDeleteVendorRate() {
    yield takeEvery(DELETE_VENDOR_RATE, deleteVendorRate);
}
const deleteVendorRateAsync = async (id) => {
    return await vendorRateApi.delete(id)
        .then(result => result)
        .catch(error => error);
}
function* deleteVendorRate({ payload }) {
    try {
        const result = yield call(deleteVendorRateAsync, payload);
        if (result.errorMessage) {
            yield put(deleteVendorRateError(result.errorMessage));
        } else {
            yield put(deleteVendorRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(deleteVendorRateError(error));

    }
}


export function* watchDeleteTemplateVendorRate() {
    yield takeEvery(DELETE_TEMPLATE_VENDOR_RATE, deleteTemplateVendorRate);
}
const deleteTemplateVendorRateAsync = async (id) => {
    return await vendorRateApi.deleteTemplate(id)
        .then(result => result)
        .catch(error => error);
}
function* deleteTemplateVendorRate({ payload }) {
    try {
        const result = yield call(deleteTemplateVendorRateAsync, payload);
        if (result.errorMessage) {
            yield put(deleteTemplateVendorRateError(result.errorMessage));
        } else {
            yield put(deleteTemplateVendorRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(deleteTemplateVendorRateError(error));

    }
}


export function* watchDetailsVendorRate() {
    yield takeEvery(DETAILS_VENDOR_RATE, detailsVendorRate);
}
const detailsVendorRateAsync = async (id) => {
    return await vendorRateApi.details(id)
        .then(result => result)
        .catch(error => error);
}
function* detailsVendorRate({ payload }) {
    try {
        const result = yield call(detailsVendorRateAsync, payload);
        if (result.errorMessage) {
            yield put(detailsVendorRateError(result.errorMessage));
        } else {
            yield put(detailsVendorRateSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(detailsVendorRateError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchCreateVendorRate),
        fork(watchReadVendorRate),
        fork(watchUpdateVendorRate),
        fork(watchDeleteVendorRate),
        fork(watchDeleteTemplateVendorRate),
        fork(watchDetailsVendorRate),
    ]);
}