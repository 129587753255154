import {
    CREATE_EXCHANGE_RATE,
    CREATE_EXCHANGE_RATE_SUCCESS,
    CREATE_EXCHANGE_RATE_ERROR,

    READ_EXCHANGE_RATE,
    READ_EXCHANGE_RATE_SUCCESS,
    READ_EXCHANGE_RATE_ERROR,
    
    UPDATE_EXCHANGE_RATE,
    UPDATE_EXCHANGE_RATE_SUCCESS,
    UPDATE_EXCHANGE_RATE_ERROR,

    DELETE_EXCHANGE_RATE,
    DELETE_EXCHANGE_RATE_SUCCESS,
    DELETE_EXCHANGE_RATE_ERROR
  } from '../actions';
  

  export const createExchangeRate = (data) => ({
    type: CREATE_EXCHANGE_RATE,
    payload: data
  });
  export const createExchangeRateSuccess = (data) => ({
    type: CREATE_EXCHANGE_RATE_SUCCESS,
    payload: data
  });
  export const createExchangeRateError = (message) => ({
    type: CREATE_EXCHANGE_RATE_ERROR,
    payload: { message }
  });
  

  export const readExchangeRate = () => ({
    type: READ_EXCHANGE_RATE,
    payload: {}
  });
  export const readExchangeRateSuccess = (data) => ({
    type: READ_EXCHANGE_RATE_SUCCESS,
    payload: data
  });
  export const readExchangeRateError = (message) => ({
    type: READ_EXCHANGE_RATE_ERROR,
    payload: { message }
  });


  export const updateExchangeRate = (data) => ({
    type: UPDATE_EXCHANGE_RATE,
    payload: data
  });
  export const updateExchangeRateSuccess = (data) => ({
    type: UPDATE_EXCHANGE_RATE_SUCCESS,
    payload: data
  });
  export const updateExchangeRateError = (message) => ({
    type: UPDATE_EXCHANGE_RATE_ERROR,
    payload: { message }
  });


  export const deleteExchangeRate = (id) => ({
    type: DELETE_EXCHANGE_RATE,
    payload: id
  });
  export const deleteExchangeRateSuccess = (data) => ({
    type: DELETE_EXCHANGE_RATE_SUCCESS,
    payload: data
  });
  export const deleteExchangeRateError = (message) => ({
    type: DELETE_EXCHANGE_RATE_ERROR,
    payload: { message }
  });