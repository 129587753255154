import {
    CREATE_LOCATION,
    CREATE_LOCATION_SUCCESS,
    CREATE_LOCATION_ERROR,

    READ_LOCATION,
    READ_LOCATION_SUCCESS,
    READ_LOCATION_ERROR,
    
    UPDATE_LOCATION,
    UPDATE_LOCATION_SUCCESS,
    UPDATE_LOCATION_ERROR,

    DELETE_LOCATION,
    DELETE_LOCATION_SUCCESS,
    DELETE_LOCATION_ERROR,

    DELETE_LOCATION_PORT,
    DELETE_LOCATION_PORT_SUCCESS,
    DELETE_LOCATION_PORT_ERROR,

    DETAILS_LOCATION,
    DETAILS_LOCATION_SUCCESS,
    DETAILS_LOCATION_ERROR,

    SEARCH_LOCATION,
    SEARCH_LOCATION_SUCCESS,
    SEARCH_LOCATION_ERROR,
  } from '../actions';
  

  export const createLocation = (data) => ({
    type: CREATE_LOCATION,
    payload: data
  });
  export const createLocationSuccess = (data) => ({
    type: CREATE_LOCATION_SUCCESS,
    payload: data
  });
  export const createLocationError = (message) => ({
    type: CREATE_LOCATION_ERROR,
    payload: { message }
  });
  

  export const readLocation = () => ({
    type: READ_LOCATION,
    payload: {}
  });
  export const readLocationSuccess = (data) => ({
    type: READ_LOCATION_SUCCESS,
    payload: data
  });
  export const readLocationError = (message) => ({
    type: READ_LOCATION_ERROR,
    payload: { message }
  });


  export const updateLocation = (data) => ({
    type: UPDATE_LOCATION,
    payload: data
  });
  export const updateLocationSuccess = (data) => ({
    type: UPDATE_LOCATION_SUCCESS,
    payload: data
  });
  export const updateLocationError = (message) => ({
    type: UPDATE_LOCATION_ERROR,
    payload: { message }
  });


  export const deleteLocation = (id) => ({
    type: DELETE_LOCATION,
    payload: id
  });
  export const deleteLocationSuccess = (data) => ({
    type: DELETE_LOCATION_SUCCESS,
    payload: data
  });
  export const deleteLocationError = (message) => ({
    type: DELETE_LOCATION_ERROR,
    payload: { message }
  });


  export const deleteLocationPort = (id) => ({
    type: DELETE_LOCATION_PORT,
    payload: id
  });
  export const deleteLocationPortSuccess = (data) => ({
    type: DELETE_LOCATION_PORT_SUCCESS,
    payload: data
  });
  export const deleteLocationPortError = (message) => ({
    type: DELETE_LOCATION_PORT_ERROR,
    payload: { message }
  });
  

  export const detailsLocation = (id) => ({
    type: DETAILS_LOCATION,
    payload: id
  });
  export const detailsLocationSuccess = (data) => ({
    type: DETAILS_LOCATION_SUCCESS,
    payload: data
  });
  export const detailsLocationError = (message) => ({
    type: DETAILS_LOCATION_ERROR,
    payload: { message }
  });


  export const searchLocation = (data) => ({
    type: SEARCH_LOCATION,
    payload: data
  });
  export const searchLocationSuccess = (data) => ({
    type: SEARCH_LOCATION_SUCCESS,
    payload: data
  });
  export const searchLocationError = (message) => ({
    type: SEARCH_LOCATION_ERROR,
    payload: { message }
  });