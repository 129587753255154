import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import vendorData from './vendor/reducer';
import resultData from './columbus/reducer';
import locations from './locations/reducers';
import exchangeRate from './exchangeRate/reducers';
import vendorRate from './vendorRate/reducers';
import selectedTemplate from './selectedTemplate/reducers';


const reducers = combineReducers({
  menu,
  settings,
  authUser,
  vendorData,
  resultData,
  locations,
  exchangeRate,
  vendorRate,
  selectedTemplate,
});

export default reducers;