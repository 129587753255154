import axios from 'axios';

const { 
    REACT_APP_API_URL
} = process.env;

const PATH = "/vendors";

const vendorApi = {
    
    get: async (request) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + PATH;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const getResult = await axios.get(
            URL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: getResult, errorMessage: errorMessage, status: status};
    },

    post: async (request) => {
        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_API_URL + PATH;

        let token = localStorage.getItem('access_token');
        let authorization = localStorage.getItem('token_type');
        
        const result = await axios.post(
            URL,
            request,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization + " " + token
                }
            })
            .catch( error => {
                status = error.response.status;

                if (error.response) {
                    // Request made and server responded
                    errorMessage = error.response.data;
                } else if (error.request) {
                    // The request was made but no response was received
                    errorMessage = error.request;
                } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                }
            });
        return { data: result, errorMessage: errorMessage, status: status};
    },
}

export default vendorApi;