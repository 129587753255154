import {
    GET_DATA,
    GET_DATA_SUCCESS,
    GET_DATA_ERROR,
    CREATE_DATA,
    CREATE_DATA_SUCCESS,
    CREATE_DATA_ERROR,
    UPDATE_DATA,
    UPDATE_DATA_SUCCESS,
    UPDATE_DATA_ERROR,
    DELETE_DATA,
    DELETE_DATA_SUCCESS,
    DELETE_DATA_ERROR,
    DELETE_ITEM_DATA,
    DELETE_ITEM_DATA_SUCCESS,
    DELETE_ITEM_DATA_ERROR,
    IMPORT_DATA,
    IMPORT_DATA_SUCCESS,
    IMPORT_DATA_ERROR,
    UPLOAD_FILE,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_ERROR
  } from '../actions';
  
  export const getData = (requestType,request, history, operation, callback = null) => ({
    type: GET_DATA,
    payload: { requestType,request, history, operation, callback }
  });
  export const getDataSuccess = (data) => ({
    type: GET_DATA_SUCCESS,
    payload: data
  });
  export const getDataError = (message, operation) => ({
    type: GET_DATA_ERROR,
    payload: { message, operation }
  });
  
  
  export const createData = (requestType, request, history, operation) => ({
    type: CREATE_DATA,
    payload: { requestType, request, history, operation }
  });
  export const createDataSuccess = (data) => ({
    type: CREATE_DATA_SUCCESS,
    payload: data
  });
  export const createDataError = (message, operation) => ({
    type: CREATE_DATA_ERROR,
    payload: { message, operation }
  });
  
  
  export const updateData = (requestType, request, history, operation) => ({
    type: UPDATE_DATA,
    payload: { requestType, request, history, operation }
  });
  export const updateDataSuccess = (data) => ({
    type: UPDATE_DATA_SUCCESS,
    payload: data
  });
  export const updateDataError = (message, operation) => ({
    type: UPDATE_DATA_ERROR,
    payload: { message, operation }
  });
  
  
  export const deleteData = (requestType, request, history) => ({
    type: DELETE_DATA,
    payload: { requestType, request, history }
  });
  export const deleteDataSuccess = (data) => ({
    type: DELETE_DATA_SUCCESS,
    payload: data
  });
  export const deleteDataError = (message) => ({
    type: DELETE_DATA_ERROR,
    payload: { message }
  });


  export const deleteItemData = (requestType, request, history) => ({
    type: DELETE_ITEM_DATA,
    payload: { requestType, request, history }
  });
  export const deleteItemDataSuccess = (data) => ({
    type: DELETE_ITEM_DATA_SUCCESS,
    payload: data
  });
  export const deleteItemDataError = (message) => ({
    type: DELETE_ITEM_DATA_ERROR,
    payload: { message }
  });


  export const importData = (requestType, request, history) => ({
    type: IMPORT_DATA,
    payload: { requestType, request, history }
  });
  export const importDataSuccess = (data) => ({
    type: IMPORT_DATA_SUCCESS,
    payload: data
  });
  export const importDataError = (message) => ({
    type: IMPORT_DATA_ERROR,
    payload: { message }
  });  
  
  export const uploadFile = (requestType, request, history) => ({
    type: UPLOAD_FILE,
    payload: { requestType, request, history }
  });
  export const uploadFileSuccess = (data) => ({
    type: UPLOAD_FILE_SUCCESS,
    payload: data
  });
  export const uploadFileError = (message) => ({
    type: UPLOAD_FILE_ERROR,
    payload: { message }
  });