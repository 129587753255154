import {
    CREATE_VENDOR_RATE,
    CREATE_VENDOR_RATE_SUCCESS,
    CREATE_VENDOR_RATE_ERROR,

    READ_VENDOR_RATE,
    READ_VENDOR_RATE_SUCCESS,
    READ_VENDOR_RATE_ERROR,
    
    UPDATE_VENDOR_RATE,
    UPDATE_VENDOR_RATE_SUCCESS,
    UPDATE_VENDOR_RATE_ERROR,

    DELETE_VENDOR_RATE,
    DELETE_VENDOR_RATE_SUCCESS,
    DELETE_VENDOR_RATE_ERROR,
    
    DELETE_TEMPLATE_VENDOR_RATE,
    DELETE_TEMPLATE_VENDOR_RATE_SUCCESS,
    DELETE_TEMPLATE_VENDOR_RATE_ERROR,
    
    DETAILS_VENDOR_RATE,
    DETAILS_VENDOR_RATE_SUCCESS,
    DETAILS_VENDOR_RATE_ERROR
} from '../actions';


const INIT_STATE = {
    loading: false,
    result: null,
    error: '', 
    created: false,
    deleted: false,
    updated: false,
    selected: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_VENDOR_RATE:
            return { 
                ...state, 
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };
        case CREATE_VENDOR_RATE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: true,
                deleted: false,
                updated: false,
                selected: false
            };
        case CREATE_VENDOR_RATE_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };

        case READ_VENDOR_RATE:
            return { 
                ...state, 
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };
        case READ_VENDOR_RATE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: true 
            };
        case READ_VENDOR_RATE_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };

        case UPDATE_VENDOR_RATE:
            return { 
                ...state, 
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };
        case UPDATE_VENDOR_RATE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false,
                deleted: false,
                updated: true,
                selected: false
            };
        case UPDATE_VENDOR_RATE_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };
        
        case DELETE_VENDOR_RATE:
            return { 
                ...state,
                loading: false,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };
        case DELETE_VENDOR_RATE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false, 
                deleted: true,
                updated: false,
                selected: false
            };
        case DELETE_VENDOR_RATE_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };

        case DELETE_TEMPLATE_VENDOR_RATE:
            return { 
                ...state,
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };
        case DELETE_TEMPLATE_VENDOR_RATE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false, 
                deleted: true,
                updated: false,
                selected: false
            };
        case DELETE_TEMPLATE_VENDOR_RATE_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };

        case DETAILS_VENDOR_RATE:
            return { 
                ...state,
                loading: true,
                result: null,
                error: '', 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };
        case DETAILS_VENDOR_RATE_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                result: action.payload, 
                error: '', 
                created: false, 
                deleted: false,
                updated: false,
                selected: true
            };
        case DETAILS_VENDOR_RATE_ERROR:
            return { 
                ...state, 
                loading: false, 
                result: null,
                error: action.payload.message, 
                created: false,
                deleted: false,
                updated: false,
                selected: false
            };

        default: return { ...state };
    }
}
