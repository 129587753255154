/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* VENDOR */
export const GET_VENDORS = "GET_VENDORS";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_VENDORS_ERROR = "GET_VENDORS_ERROR";
export const TOGGLE_VENDOR = "TOGGLE_VENDOR";
export const TOGGLE_VENDOR_SUCCESS = "TOGGLE_VENDOR_SUCCESS";
export const TOGGLE_VENDOR_ERROR = "TOGGLE_VENDOR_ERROR";


/* GENERIC API */
export const GET_DATA = "GET_DATA";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const GET_DATA_ERROR = "GET_DATA_ERROR";
export const CREATE_DATA = "CREATE_DATA";
export const CREATE_DATA_SUCCESS = "CREATE_DATA_SUCCESS";
export const CREATE_DATA_ERROR = "CREATE_DATA_ERROR";
export const UPDATE_DATA = "UPDATE_DATA";
export const UPDATE_DATA_SUCCESS = "UPDATE_DATA_SUCCESS";
export const UPDATE_DATA_ERROR = "UPDATE_DATA_ERROR";
export const DELETE_DATA = "DELETE_DATA";
export const DELETE_DATA_SUCCESS = "DELETE_DATA_SUCCESS";
export const DELETE_DATA_ERROR = "DELETE_DATA_ERROR";
export const DELETE_ITEM_DATA = "DELETE_ITEM_DATA";
export const DELETE_ITEM_DATA_SUCCESS = "DELETE_ITEM_DATA_SUCCESS";
export const DELETE_ITEM_DATA_ERROR = "DELETE_ITEM_DATA_ERROR";
export const IMPORT_DATA = "IMPORT_DATA";
export const IMPORT_DATA_SUCCESS = "IMPORT_DATA_SUCCESS";
export const IMPORT_DATA_ERROR = "IMPORT_DATA_ERROR";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";


export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";

/* LOCATIONS */
export const CREATE_LOCATION = "CREATE_LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_ERROR = "CREATE_LOCATION_ERROR";

export const READ_LOCATION = "READ_LOCATION";
export const READ_LOCATION_SUCCESS = "READ_LOCATION_SUCCESS";
export const READ_LOCATION_ERROR = "READ_LOCATION_ERROR";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR";

export const DELETE_LOCATION_PORT = "DELETE_LOCATION_PORT";
export const DELETE_LOCATION_PORT_SUCCESS = "DELETE_LOCATION_PORT_SUCCESS";
export const DELETE_LOCATION_PORT_ERROR = "DELETE_LOCATION_PORT_ERROR";

export const DETAILS_LOCATION = "DETAILS_LOCATION";
export const DETAILS_LOCATION_SUCCESS = "DETAILS_LOCATION_SUCCESS";
export const DETAILS_LOCATION_ERROR = "DETAILS_LOCATION_ERROR";

export const SEARCH_LOCATION = "SEARCH_LOCATION";
export const SEARCH_LOCATION_SUCCESS = "SEARCH_LOCATION_SUCCESS";
export const SEARCH_LOCATION_ERROR = "SEARCH_LOCATION_ERROR";

export * from "./locations/actions";
export * from "./vendor/actions";
export * from "./columbus/actions";

/* EXCHANGE RATE */
export const CREATE_EXCHANGE_RATE = "CREATE_EXCHANGE_RATE";
export const CREATE_EXCHANGE_RATE_SUCCESS = "CREATE_EXCHANGE_RATE_SUCCESS";
export const CREATE_EXCHANGE_RATE_ERROR = "CREATE_EXCHANGE_RATE_ERROR";

export const READ_EXCHANGE_RATE = "READ_EXCHANGE_RATE";
export const READ_EXCHANGE_RATE_SUCCESS = "READ_EXCHANGE_RATE_SUCCESS";
export const READ_EXCHANGE_RATE_ERROR = "READ_EXCHANGE_RATE_ERROR";

export const UPDATE_EXCHANGE_RATE = "UPDATE_EXCHANGE_RATE";
export const UPDATE_EXCHANGE_RATE_SUCCESS = "UPDATE_EXCHANGE_RATE_SUCCESS";
export const UPDATE_EXCHANGE_RATE_ERROR = "UPDATE_EXCHANGE_RATE_ERROR";

export const DELETE_EXCHANGE_RATE = "DELETE_EXCHANGE_RATE";
export const DELETE_EXCHANGE_RATE_SUCCESS = "DELETE_EXCHANGE_RATE_SUCCESS";
export const DELETE_EXCHANGE_RATE_ERROR = "DELETE_EXCHANGE_RATE_ERROR";

export * from "./exchangeRate/actions";

/* VENDOR RATE */
export const CREATE_VENDOR_RATE = "CREATE_VENDOR_RATE";
export const CREATE_VENDOR_RATE_SUCCESS = "CREATE_VENDOR_RATE_SUCCESS";
export const CREATE_VENDOR_RATE_ERROR = "CREATE_VENDOR_RATE_ERROR";

export const READ_VENDOR_RATE = "READ_VENDOR_RATE";
export const READ_VENDOR_RATE_SUCCESS = "READ_VENDOR_RATE_SUCCESS";
export const READ_VENDOR_RATE_ERROR = "READ_VENDOR_RATE_ERROR";

export const UPDATE_VENDOR_RATE = "UPDATE_VENDOR_RATE";
export const UPDATE_VENDOR_RATE_SUCCESS = "UPDATE_VENDOR_RATE_SUCCESS";
export const UPDATE_VENDOR_RATE_ERROR = "UPDATE_VENDOR_RATE_ERROR";

export const DELETE_VENDOR_RATE = "DELETE_VENDOR_RATE";
export const DELETE_VENDOR_RATE_SUCCESS = "DELETE_VENDOR_RATE_SUCCESS";
export const DELETE_VENDOR_RATE_ERROR = "DELETE_VENDOR_RATE_ERROR";

export const DELETE_TEMPLATE_VENDOR_RATE = "DELETE_TEMPLATE_VENDOR_RATE";
export const DELETE_TEMPLATE_VENDOR_RATE_SUCCESS = "DELETE_TEMPLATE_VENDOR_RATE_SUCCESS";
export const DELETE_TEMPLATE_VENDOR_RATE_ERROR = "DELETE_TEMPLATE_VENDOR_RATE_ERROR";

export const DETAILS_VENDOR_RATE = "DETAILS_VENDOR_RATE";
export const DETAILS_VENDOR_RATE_SUCCESS = "DETAILS_VENDOR_RATE_SUCCESS";
export const DETAILS_VENDOR_RATE_ERROR = "DETAILS_VENDOR_RATE_ERROR";

export * from "./vendorRate/actions";

/* SELECTED TEMPLATE */
export const CREATE_SELECTED_TEMPLATE = "CREATE_SELECTED_TEMPLATE";
export const CREATE_SELECTED_TEMPLATE_SUCCESS = "CREATE_SELECTED_TEMPLATE_SUCCESS";
export const CREATE_SELECTED_TEMPLATE_ERROR = "CREATE_SELECTED_TEMPLATE_ERROR";

export const READ_SELECTED_TEMPLATE = "READ_SELECTED_TEMPLATE";
export const READ_SELECTED_TEMPLATE_SUCCESS = "READ_SELECTED_TEMPLATE_SUCCESS";
export const READ_SELECTED_TEMPLATE_ERROR = "READ_SELECTED_TEMPLATE_ERROR";

export const UPDATE_SELECTED_TEMPLATE = "UPDATE_SELECTED_TEMPLATE";
export const UPDATE_SELECTED_TEMPLATE_SUCCESS = "UPDATE_SELECTED_TEMPLATE_SUCCESS";
export const UPDATE_SELECTED_TEMPLATE_ERROR = "UPDATE_SELECTED_TEMPLATE_ERROR";

export const DELETE_SELECTED_TEMPLATE = "DELETE_SELECTED_TEMPLATE";
export const DELETE_SELECTED_TEMPLATE_SUCCESS = "DELETE_SELECTED_TEMPLATE_SUCCESS";
export const DELETE_SELECTED_TEMPLATE_ERROR = "DELETE_SELECTED_TEMPLATE_ERROR";

export const DELETE_TEMPLATE_SELECTED_TEMPLATE = "DELETE_TEMPLATE_SELECTED_TEMPLATE";
export const DELETE_TEMPLATE_SELECTED_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SELECTED_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_SELECTED_TEMPLATE_ERROR = "DELETE_TEMPLATE_SELECTED_TEMPLATE_ERROR";

export const DETAILS_SELECTED_TEMPLATE = "DETAILS_SELECTED_TEMPLATE";
export const DETAILS_SELECTED_TEMPLATE_SUCCESS = "DETAILS_SELECTED_TEMPLATE_SUCCESS";
export const DETAILS_SELECTED_TEMPLATE_ERROR = "DETAILS_SELECTED_TEMPLATE_ERROR";

export * from "./selectedTemplate/actions";
