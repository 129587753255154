import {
    CREATE_VENDOR_RATE,
    CREATE_VENDOR_RATE_SUCCESS,
    CREATE_VENDOR_RATE_ERROR,

    READ_VENDOR_RATE,
    READ_VENDOR_RATE_SUCCESS,
    READ_VENDOR_RATE_ERROR,
    
    UPDATE_VENDOR_RATE,
    UPDATE_VENDOR_RATE_SUCCESS,
    UPDATE_VENDOR_RATE_ERROR,

    DELETE_VENDOR_RATE,
    DELETE_VENDOR_RATE_SUCCESS,
    DELETE_VENDOR_RATE_ERROR,

    DELETE_TEMPLATE_VENDOR_RATE,
    DELETE_TEMPLATE_VENDOR_RATE_SUCCESS,
    DELETE_TEMPLATE_VENDOR_RATE_ERROR,

    DETAILS_VENDOR_RATE,
    DETAILS_VENDOR_RATE_SUCCESS,
    DETAILS_VENDOR_RATE_ERROR
  } from '../actions';
  

  export const createVendorRate = (data) => ({
    type: CREATE_VENDOR_RATE,
    payload: data
  });
  export const createVendorRateSuccess = (data) => ({
    type: CREATE_VENDOR_RATE_SUCCESS,
    payload: data
  });
  export const createVendorRateError = (message) => ({
    type: CREATE_VENDOR_RATE_ERROR,
    payload: { message }
  });
  

  export const readVendorRate = () => ({
    type: READ_VENDOR_RATE,
    payload: {}
  });
  export const readVendorRateSuccess = (data) => ({
    type: READ_VENDOR_RATE_SUCCESS,
    payload: data
  });
  export const readVendorRateError = (message) => ({
    type: READ_VENDOR_RATE_ERROR,
    payload: { message }
  });


  export const updateVendorRate = (data) => ({
    type: UPDATE_VENDOR_RATE,
    payload: data
  });
  export const updateVendorRateSuccess = (data) => ({
    type: UPDATE_VENDOR_RATE_SUCCESS,
    payload: data
  });
  export const updateVendorRateError = (message) => ({
    type: UPDATE_VENDOR_RATE_ERROR,
    payload: { message }
  });


  export const deleteVendorRate = (id) => ({
    type: DELETE_VENDOR_RATE,
    payload: id
  });
  export const deleteVendorRateSuccess = (data) => ({
    type: DELETE_VENDOR_RATE_SUCCESS,
    payload: data
  });
  export const deleteVendorRateError = (message) => ({
    type: DELETE_VENDOR_RATE_ERROR,
    payload: { message }
  });


  export const deleteTemplateVendorRate = (id) => ({
    type: DELETE_TEMPLATE_VENDOR_RATE,
    payload: id
  });
  export const deleteTemplateVendorRateSuccess = (data) => ({
    type: DELETE_TEMPLATE_VENDOR_RATE_SUCCESS,
    payload: data
  });
  export const deleteTemplateVendorRateError = (message) => ({
    type: DELETE_TEMPLATE_VENDOR_RATE_ERROR,
    payload: { message }
  });


  export const detailsVendorRate = (id) => ({
    type: DETAILS_VENDOR_RATE,
    payload: id
  });
  export const detailsVendorRateSuccess = (data) => ({
    type: DETAILS_VENDOR_RATE_SUCCESS,
    payload: data
  });
  export const detailsVendorRateError = (message) => ({
    type: DETAILS_VENDOR_RATE_ERROR,
    payload: { message }
  });