
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import vendorApi from '../../api/Vendor';
import {
    GET_VENDORS,
    TOGGLE_VENDOR
} from '../actions';

import {
    getVendorsSuccess,
    getVendorsError,
    toggleVendorSuccess,
    toggleVendorError
} from './actions';


export function* watchGetVendors() {
    yield takeEvery(GET_VENDORS, getVendors);
}

const getVendorsAsync = async (request) =>
    await vendorApi.get(request)
        .then(vendorData => vendorData)
        .catch(error => error);

function* getVendors({ payload }) {
    const { request } = payload;
    const { history } = payload;
    try {
        const vendorData = yield call(getVendorsAsync, request);
        if(vendorData.status === 401){
            window.localStorage.clear();
            window.location.href = "/";
        }
        if (vendorData.errorMessage) {
            yield put(getVendorsError(vendorData.errorMessage));
        } else {
            yield put(getVendorsSuccess(vendorData.data.data.data));
            
        }
    } catch (error) {
        yield put(getVendorsError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchGetVendors),
        //fork(watchLogoutUser)
    ]);
}