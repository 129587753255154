
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import locationsApi from '../../api/Locations';

import {
    CREATE_LOCATION,
    READ_LOCATION,
    UPDATE_LOCATION,
    DELETE_LOCATION,
    DELETE_LOCATION_PORT,
    DETAILS_LOCATION,
    SEARCH_LOCATION
} from '../actions';

import {
    createLocationSuccess,
    createLocationError,

    readLocationSuccess,
    readLocationError,

    updateLocationSuccess,
    updateLocationError,

    deleteLocationSuccess,
    deleteLocationError,

    deleteLocationPortSuccess,
    deleteLocationPortError,

    detailsLocationSuccess,
    detailsLocationError,

    searchLocationSuccess,
    searchLocationError
} from './actions';



export function* watchCreateLocation() {
    yield takeEvery(CREATE_LOCATION, createLocation);
}
const createLocationAsync = async (data) => {
    return await locationsApi.create(data)
        .then(result => result)
        .catch(error => error);
}
function* createLocation({ payload }) {
    
    try {
        const result = yield call(createLocationAsync, payload);
        if (result.errorMessage) {
            yield put(createLocationError(result.errorMessage));
        } else {
            yield put(createLocationSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(createLocationError(error));

    }
}


export function* watchReadLocation() {
    yield takeEvery(READ_LOCATION, readLocation);
}
const readLocationAsync = async (data) => {
    return await locationsApi.read(data)
        .then(result => result)
        .catch(error => error);
}
function* readLocation({ payload }) {
    try {
        const result = yield call(readLocationAsync, payload);
        if(result.status === 401){
            window.localStorage.clear();
            window.location.href = "/";
        }
        if (result.errorMessage) {
            yield put(readLocationError(result.errorMessage));
        } else {
            yield put(readLocationSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(readLocationError(error));

    }
}
        

export function* watchUpdateLocation() {
    yield takeEvery(UPDATE_LOCATION, updateLocation);
}
const updateLocationAsync = async (data) => {
    return await locationsApi.update(data)
        .then(result => result)
        .catch(error => error);
}
function* updateLocation({ payload }) {
    try {
        const result = yield call(updateLocationAsync, payload);
        if (result.errorMessage) {
            yield put(updateLocationError(result.errorMessage));
        } else {
            yield put(updateLocationSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(updateLocationError(error));

    }
}


export function* watchDeleteLocation() {
    yield takeEvery(DELETE_LOCATION, deleteLocation);
}
const deleteLocationAsync = async (id) => {
    return await locationsApi.delete(id)
        .then(result => result)
        .catch(error => error);
}
function* deleteLocation({ payload }) {
    try {
        const result = yield call(deleteLocationAsync, payload);
        if (result.errorMessage) {
            yield put(deleteLocationError(result.errorMessage));
        } else {
            yield put(deleteLocationSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(deleteLocationError(error));

    }
}


export function* watchDeleteLocationPort() {
    yield takeEvery(DELETE_LOCATION_PORT, deleteLocationPort);
}
const deleteLocationPortAsync = async (id) => {
    return await locationsApi.deletePort(id)
        .then(result => result)
        .catch(error => error);
}
function* deleteLocationPort({ payload }) {
    try {
        const result = yield call(deleteLocationPortAsync, payload);
        if (result.errorMessage) {
            yield put(deleteLocationPortError(result.errorMessage));
        } else {
            yield put(deleteLocationPortSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(deleteLocationPortError(error));

    }
}


export function* watchDetailsLocation() {
    yield takeEvery(DETAILS_LOCATION, detailsLocation);
}
const detailsLocationAsync = async (id) => {
    return await locationsApi.searchByContry(id)
        .then(result => result)
        .catch(error => error);
}
function* detailsLocation({ payload }) {
    try {
        const result = yield call(detailsLocationAsync, payload);
        if (result.errorMessage) {
            yield put(detailsLocationError(result.errorMessage));
        } else {
            yield put(detailsLocationSuccess(result.data.data.locations));
        }
    } catch (error) {
        yield put(detailsLocationError(error));

    }
}


export function* watchSearchLocation() {
    yield takeEvery(SEARCH_LOCATION, searchLocation);
}
const searchLocationAsync = async (data) => {
    return await locationsApi.search(data)
        .then(result => result)
        .catch(error => error);
}
function* searchLocation({ payload }) {
    try {
        const result = yield call(searchLocationAsync, payload);
        if (result.errorMessage) {
            yield put(searchLocationError(result.errorMessage));
        } else {
            yield put(searchLocationSuccess(result.data.data.data));
        }
    } catch (error) {
        yield put(searchLocationError(error));

    }
}


export default function* rootSaga() {
    yield all([
        fork(watchCreateLocation),
        fork(watchReadLocation),
        fork(watchUpdateLocation),
        fork(watchDeleteLocation),
        fork(watchDeleteLocationPort),
        fork(watchDetailsLocation),
        fork(watchSearchLocation),
    ]);
}